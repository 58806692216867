@import "../../colors.scss";

.visitor-info-pane {
  padding: 0 10px;
  border-left: 1px solid $pane-border;
  flex: 0 240px;
  background: lighten( $theme-color, 65% );
  overflow: scroll;

  width: 240px;

  p {
    margin-left: 10px;
  }

  .user-agent-icon {
    margin-right: 2px;
  }

  .visitor {
    margin-bottom: 20px;

    h1 {
      margin-bottom: 8px;
    }

    .flag {
      margin-right: 4px;
      span {
        top: -2px;
      }
    }

    .icon {
      margin-right: 3px;
      svg {
        position: relative;
        top: 2px;
      }
    }

    .email {
      color: #999999;
    }

    p {
      margin-top: 0;
      margin-bottom: 6px;
      padding: 0;
    }
  }

  .agent {
    border: 1px solid $pane-border;
    border-radius: 30px;
    padding: 3px 10px;
    margin: 10px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;

    svg {
      font-size: 24px;
      margin-top: 2px;
    }

    span {
      padding: 0;
      margin: 0 3px;
      line-height: 20px;
      vertical-align: middle;
    }
  }

  section {
    border: 1px solid $pane-border;
    border-radius: 10px;
    margin: 5px 10px;
    padding: 12px;

    .icons {
      margin-bottom: 5px;

      .timestamp {
        float: right;
        color: #999999;

        &:before {
          content: 'since ';
        }
      }


    }

    .path {
      font-family: Tahoma, serif;
      font-size: 15px;
      display: inline-block;



      .path-entry {
        &:first-child {
          button {
            margin-right: 6px;
            vertical-align: middle;
            width: 28px;
            height: 28px;
            padding: 3px;
          }
        }

        &:not(:first-child) {
          margin-top: 4px;

          &:before {
            display: inline-block;
            margin-left: 10px;
            margin-right: 14px;
            border-radius: 100%;
            content: ' ';
            width: 10px;
            height: 10px;
            background: $pane-border;
          }
        }
      }
    }
  }
}
