@import "../../colors.scss";

.serve-fab {
  position: absolute;
  bottom: -40px;
  left: 38px;
  opacity: 0;
  transition: opacity .5s;

  &.visible {
    bottom: 20px;
    opacity: 1;
    transition: opacity .25s, bottom .25s;
  }

  .button {
    background: $theme-color;
    box-shadow: 0 0 5px 0 rgba(110,110,110,0.75);
    transition: box-shadow .5s;

    svg {
      color: white;
      transition: color .5s;
    }

    &:hover {
      box-shadow: 0 0 10px 0 rgba(110,110,110,0.75);
      transition: box-shadow .3s;

      svg {
        color: $theme-color;
        transition: color .3s;
      }
    }
  }
}