$theme-color-gradient1: #04ff99;
$theme-color-gradient2: #04dd99;
$theme-color: #04aa99;
$pane-border: #e8e8e8;
$active-background: #f4f4f4;

$status-active: $theme-color-gradient1;
$status-inactive: #ffdd99;
$status-idle: #ffdd99;
$status-offline: #dddddd;
