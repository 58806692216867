a {
  color: black;
  text-decoration: none;
  cursor: default;

  &.real-link { cursor: pointer; }

  &.real-link:hover {
    text-decoration: underline;
  }
}

h1 {
  font-size: 24px;
  font-family: "Helvetica Neue", serif;
  font-weight: bold;
  margin: 16px 10px 20px 10px;
}

h4 {
  margin: 24px 10px 16px 10px;
}

.filler, .full-width {
  border: none !important;
  flex: 1 !important;
}

.conversation-footer {
  font-style: italic;
  margin: 3px 5px;
  color: #999999;
  font-size: 12px;
}

@media (min-device-width: 900px) {
  .mobile-only {
    display: none;
  }

  .desktop-filler {
    flex: 1 !important;
  }
}

@media (max-device-width: 900px) {
  .desktop-only:not(.force-desktop) {
    display: none;
  }

  .force-desktop .desktop-only {
    display: block;
  }

  .flex-2-mobile {
    flex: 2 !important;
  }

  .mobile-filler {
    flex: 1 !important;
  }

  .conversation-header .username {
    position: relative;
    top: -6px;
  }
}