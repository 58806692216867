/*!
  Theme: Windows 95 Light
  Author: Fergus Collins (https://github.com/C-Fergus)
  License: ~ MIT (or more permissive) [via base16-schemes-source]
  Maintainer: @highlightjs/core-team
  Version: 2021.09.0
*/
pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em
}

code.hljs {
  padding: 3px 5px
}

.hljs {
  color: #545454;
  background: #fcfcfc
}

.hljs ::selection,
.hljs::selection {
  background-color: #c4c4c4;
  color: #545454
}

.hljs-comment {
  color: #a8a8a8
}

.hljs-tag {
  color: #7e7e7e
}

.hljs-operator,
.hljs-punctuation,
.hljs-subst {
  color: #545454
}

.hljs-operator {
  opacity: .7
}

.hljs-bullet,
.hljs-deletion,
.hljs-name,
.hljs-selector-tag,
.hljs-template-variable,
.hljs-variable {
  color: #a80000
}

.hljs-attr,
.hljs-link,
.hljs-literal,
.hljs-number,
.hljs-symbol,
.hljs-variable.constant_ {
  color: #2448e5
}

.hljs-class .hljs-title,
.hljs-title,
.hljs-title.class_ {
  color: #a85400
}

.hljs-strong {
  font-weight: 700;
  color: #a85400
}

.hljs-addition,
.hljs-code,
.hljs-string,
.hljs-title.class_.inherited__ {
  color: #2448e5
}

.hljs-built_in,
.hljs-doctag,
.hljs-keyword.hljs-atrule,
.hljs-quote,
.hljs-regexp {
  color: #00a8a8
}

.hljs-attribute,
.hljs-function .hljs-title,
.hljs-section,
.hljs-title.function_,
.ruby .hljs-property {
  color: #0000a8
}

.diff .hljs-meta,
.hljs-keyword,
.hljs-template-tag,
.hljs-type {
  color: #a800a8
}

.hljs-emphasis {
  color: #a800a8;
  font-style: italic
}

.hljs-meta,
.hljs-meta .hljs-keyword,
.hljs-meta .hljs-string {
  color: #2448e5
}

.hljs-meta .hljs-keyword,
.hljs-meta-keyword {
  font-weight: 700
}