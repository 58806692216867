.logiscool-support-chat-widget {
  position: fixed;
  bottom: 40px;
  right: 50px;
  width: 0;
  height: 0;
  border: none;
  background: transparent;
  z-index: 99999999999;

  &.open {
    width: 300px;
    height: 360px;
  }

  &.btn {
    bottom: 20px;
    right: 30px;
    width: 65px;
    height: 65px;
  }
}

@media (max-device-width: 900px) {
  .logiscool-support-chat-widget {
    &.open {
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }
}