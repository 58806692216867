@import "../../colors.scss";

@keyframes typing {
  0% { content: ' typing'; }
  33% { content: ' typing.'; }
  66% { content: ' typing..'; }
  100% { content: ' typing...'; }
}

.message-list {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: scroll;
  padding: 0 15px;

  //HACK: as justify-content: flex-end does not work
  //https://stackoverflow.com/a/37515194
  > :first-child {
    margin-top: auto !important;
  }

  .service-message {
    text-align: center;
    //margin: 8px 20px 16px 20px;
    margin: 10px 20px;
    font-size: 12px;
    position: relative;
    //height: 28px;

    > .divider {
      height: 1px;
      background-color: $pane-border;
      width: 100%;
      position: absolute;
      top: 50%;
    }

    > span {
      position: relative;
      display: inline-block;
      border: 1px solid $pane-border;
      border-radius: 30px;
      padding: 4px 12px;
      color: #999999;
      background: white;
      //top: 50%;
      max-width: 80%;
    }
  }

  .timestamp {
    text-align: center;
    color: #999999;
    font-size: 12px;
    margin: 8px;
    text-transform: uppercase;

    &.invisible {
      margin: 0;
    }
  }

  .chat-bubble-outer {
    text-align: right;

    .chat-bubble {
      //word-break: break-all;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      user-select: text;
      background-attachment: fixed;
      background-color: $theme-color;
      //background-image: linear-gradient($theme-color-gradient1 0%, $theme-color-gradient2 50%, $theme-color 100%);
      opacity: 0.7;
      color: white;
      margin: 1px 3px;
      padding: 6px 12px 7px;
      display: inline-block;
      max-width: 70%;
      text-align: left;
      border-radius: 5px;
    }

    .username {
      font-size: 12px;
      font-weight: bold;
      margin: 4px 5px 0 5px;
    }

    &.incoming {
      text-align: left;

      .chat-bubble {
        background: #f1f0f0;
        opacity: 1;
        color: black;
        text-align: left;
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
        max-width: 95%;
      }
    }

    &:not(.incoming) {
      .chat-bubble {
        border-top-left-radius: 17px;
        border-bottom-left-radius: 17px;
      }
    }

    &.delivered {
      .chat-bubble {
        opacity: 1;
      }
    }

    &.image-type {
      .chat-bubble {
        padding: 0;
        border: 1px solid #e8e8e8;
        overflow: hidden;
        display: inline-flex;
        cursor: pointer;
      }
    }
  }

  .typings {
    margin-top: 2px;
  }

  .read-receipts {
    text-align: right;
  }

  .chat-bubble-outer.incoming:first-of-type .chat-bubble,
  .chat-bubble-outer:not(.incoming) + .chat-bubble-outer.incoming .chat-bubble,
  .service-message + .chat-bubble-outer.incoming .chat-bubble,
  .timestamp + .chat-bubble-outer.incoming .chat-bubble {
    border-top-left-radius: 17px;
    margin-top: 3px;
  }

  .chat-bubble-outer:not(.incoming):first-of-type .chat-bubble,
  .chat-bubble-outer.incoming + .chat-bubble-outer:not(.incoming) .chat-bubble,
  .service-message + .chat-bubble-outer:not(.incoming) .chat-bubble,
  .timestamp + .chat-bubble-outer:not(.incoming) .chat-bubble {
    border-top-right-radius: 17px;
    margin-top: 3px;
  }

  .chat-bubble-outer.incoming.last .chat-bubble {
    border-bottom-left-radius: 17px;
    margin-bottom: 3px;
  }

  .chat-bubble-outer:not(.incoming).last .chat-bubble {
    border-bottom-right-radius: 17px;
    margin-bottom: 3px;
  }

  .chat-bubble-outer.incoming > .username + .chat-bubble {
    border-top-left-radius: 5px !important;
  }

  .chat-bubble-outer:not(.incoming) > .username + .chat-bubble {
    border-top-left-radius: 5px !important;
  }

  .chat-bubble-outer.incoming + div > .conversation-footer > .read-receipts {
    text-align: left;
  }

  .reader-username:first-of-type:before {
    content: '✓ ';
  }

  .reader-username:not(:last-of-type):after {
    content: ', ';
  }

  .typing-username:last-of-type:after {
    content: ' typing...';
    animation: typing 1s;
    animation-iteration-count: infinite;
    backface-visibility: hidden;
    transform-origin: center center;
  }

  .typing-username:not(:last-of-type):after {
    content: ', ';
  }
}

.image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    animation: fade-in .1s ease-in-out;

    img {
        max-width: 100vw;
        max-height: 100vh;
        animation: scale-in .2s ease-in-out;
        border-radius: 5px;
        overflow: hidden;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scale-in {
    0% {
        transform: scale(.9);
    }
    100% {
        transform: scale(1);
    }
}
