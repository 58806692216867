@import "../../colors.scss";

@keyframes bellshake {
  0% { transform: rotate(0); }
  10% { transform: rotate(0); }
  15% { transform: rotate(5deg); }
  30% { transform: rotate(-5deg); }
  45% { transform: rotate(4deg); }
  60% { transform: rotate(-4deg); }
  75% { transform: rotate(2deg); }
  85% { transform: rotate(-2deg); }
  92% { transform: rotate(1deg); }
  100% { transform: rotate(0); }
}

.button {
  height: 30px;
  border-radius: 30px;
  border: 1px solid #f4f4f4;
  font-size: 14px;
  padding: 2px 8px;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  background: white;
  transition: background .3s, border-color .3s;

  svg {
    font-size: 20px;
  }

  &:not(.disabled):hover, &.active {
    transition: background .1s, border-color .1s;
    background: #f4f4f4;
    border: 1px solid #999999;
  }

  &.round {
    width: 30px;
    border-radius: 100%;
    padding: 4px;
    font-size: 12px;
    margin: 0;
  }

  &.large {
    min-width: 45px;
    height: 45px;

    svg {
      font-size: 24px;
    }
  }

  &.blink {
    animation: bellshake .5s cubic-bezier(.36,.07,.19,.97) both;
    animation-iteration-count: infinite;
    backface-visibility: hidden;
    transform-origin: center center;
  }

  &.primary {
    color: $theme-color;
    border: 1px solid #999999;
  }

  &.transparent {
    background: transparent;
    border: 1px solid #444444;

    &:not(.disabled):hover {
      border: 1px solid #222222;
      background: darken($theme-color,10%);
    }
  }

  &.themecolor {
    background: $theme-color;
    border: 1px solid #444444;
  }

  > span {
    vertical-align: middle;
    line-height: 14px;
  }

  .button-icon + .button-label {
    margin-left: 2px;
  }
}

.button-group {
  > .button {
    > .button-label.desktop-only {
      display: block;
    }

    border-radius: 0;
    margin: 0;
    border-right-width: 0;
  }

  .button.active {
    border-right-width: 1px;
  }

  > .button:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-left: 4px;
    margin-right: 0;
  }

  > .button:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left: 0;
    margin-right: 4px;
    border-right-width: 1px;
  }
}