.message-input {
  flex: 40px 0;
  padding: 8px 15px 15px 15px;
  text-align: center;

  .input {
    display: block;
    text-align: left;
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    background: #f4f4f4;
    border: none;
    border-radius: 18px;
    padding: 10px 12px;
    font-size: 14px;
    outline: none;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    user-select: text;

    &:empty:before{
      content: attr(placeholder);
      display: block; /* For Firefox */
      color: #8d8d8d;
    }
  }
}