@import "../../colors.scss";

.test-page {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  z-index: 1000000;

  .tester {
    padding: 20px;

    > * {
      margin: 5px;
    }

    display: flex;
    flex-flow: column nowrap;
  }

}