.loader {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-indicator {
    display: inline-block;
    text-align: center;
    flex: 1;
  }
}